import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{class:_vm.gs.isMobile() ? '' : 'dxa_modal'},[_c(VCardTitle,{staticClass:"mb-2"},[_c('h4',{staticClass:"dxa_modal_title h4"},[_vm._v(" "+_vm._s(_vm.$t("select_manager_company_default"))+" ")])]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.checkForm()}}},[_c('div',{staticClass:"select-manager-partner-default-container"},[_c('div',{staticClass:"tooltip-container"},[_c(VTooltip,{attrs:{"bottom":"","color":"gray"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('font-awesome-icon',{staticClass:"icon-tooltip-manager",attrs:{"icon":"fa-solid fa-circle-info"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("write_min_3_caracteres")))])])],1),_c(VAutocomplete,{staticClass:"rounds-filters-auto-complete",attrs:{"items":_vm.managerPartnerList,"item-text":"name","item-value":"id","color":"primary","rules":[_vm.required],"dense":"","label":_vm.$t('select_label'),"outlined":"","loading":_vm.managerPartnerListLoading},on:{"keyup":_vm.managerPartnerChanged},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressCircular,{staticStyle:{"position":"absolute","right":"40px","top":"10px"},attrs:{"indeterminate":"","size":"20","color":"primary"}})]},proxy:true},{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.managerPartnerFallback ? _vm.$t("enter_manager_company") : _vm.$t("not_found"))+" ")])],1)]},proxy:true}]),model:{value:(_vm.selectedManagerPartner),callback:function ($$v) {_vm.selectedManagerPartner=$$v},expression:"selectedManagerPartner"}})],1),_c(VCardActions,{staticClass:"pl-0 dxa_modal_actions"},[_c(VBtn,{staticClass:"dxa_modal_btnSuccess",attrs:{"color":"primary","min-width":"100","loading":_vm.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("save")))]),_c(VBtn,{staticClass:"dxa_modal_btnError",attrs:{"color":"red","min-width":"100"},on:{"click":function () { return _vm.$emit('close'); }}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1),(_vm.alert.show)?_c(VAlert,{staticClass:"ma-2",attrs:{"type":_vm.alert.type}},[_vm._v(_vm._s(_vm.alert.message))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }