<template>
  <v-container id="category-table" fluid tag="section">
    <ManagerPartnerTable />
  </v-container>
</template>

<script>
import ManagerPartnerTable from "./components/ManagerPartnerTable.vue";

export default {
  name: "Manager",
  components: {
    ManagerPartnerTable,
  },
};
</script>

<style scoped></style>
