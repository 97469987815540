<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
    <v-card-title class="mb-2">
      <h4 class="dxa_modal_title h4">
        {{ $t("select_manager_company_default") }}
      </h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="checkForm()" ref="form">
        <div class="select-manager-partner-default-container">
          <div class="tooltip-container">
            <v-tooltip bottom color="gray">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-info"
                    class="icon-tooltip-manager"
                  />
                </div>
              </template>
              <span>{{ $t("write_min_3_caracteres") }}</span>
            </v-tooltip>
          </div>
          <v-autocomplete
            :items="managerPartnerList"
            @keyup="managerPartnerChanged"
            item-text="name"
            item-value="id"
            color="primary"
            :rules="[required]"
            dense
            class="rounds-filters-auto-complete"
            :label="$t('select_label')"
            outlined
            v-model="selectedManagerPartner"
            :loading="managerPartnerListLoading"
          >
            <template v-slot:progress>
              <v-progress-circular
                indeterminate
                style="position: absolute; right: 40px; top: 10px"
                size="20"
                color="primary"
              ></v-progress-circular>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{
                    managerPartnerFallback
                      ? $t("enter_manager_company")
                      : $t("not_found")
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="() => $emit('close')"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-alert class="ma-2" v-if="alert.show" :type="alert.type">{{
        alert.message
      }}</v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import GeneralServices from "@/services/GeneralServices";
import ApiService from "@/services/ApiService";

export default {
  name: "ManagerPartnerSettings",
  data() {
    return {
      apiService: new ApiService(),
      gs: new GeneralServices(),
      managerPartnerList: [],
      selectedManagerPartner: null,
      managerPartnerListLoading: false,
      managerPartnerFallback: true,
      loading: false,
      alert: {
        show: false,
        type: "success",
        message: null,
      },
      defaultManager: {
        id: null,
        name: null,
        default: false,
      },
    };
  },
  async created() {
    await this.getManagerPartnerDefault();
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    async checkForm() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      let result = this.$refs.form.validate();

      if (result) {
        await this.submit();
      } else {
        this.loading = false;
      }
    },
    handleEmitAlert(type, message) {
      this.alert.show = true;
      this.alert.type = type;
      this.alert.message = this.$t(message);
    },
    async getManagerPartnerDefault() {
      this.loading = true;

      await this.apiService
        .getRequest(`managerpartner/default`)
        .then((resp) => {
          const defaultManager = resp.content;
          this.defaultManager = defaultManager;

          // se o default manager existir, vamos popular a comba com o objeto default
          // e o v-model, para a combo começar marcada.
          if (defaultManager) {
            this.managerPartnerList = [{ ...defaultManager }];
            this.selectedManagerPartner = defaultManager.id;
          }
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.loading = false;
    },
    async submit() {
      this.loading = true;
      this.alert.show = false;

      await this.apiService
        .putRequest(`managerpartner/${this.selectedManagerPartner}/default`)
        .then((resp) => {
          this.handleEmitAlert("success", "saved");
        })
        .catch((err) => {
          this.handleEmitAlert(
            "error",
            err.body.message ? err.body.message : "an_error_ocurred"
          );
        });

      this.loading = false;
    },
    async managerPartnerChanged(e) {
      const filterName = e.target.value;

      if (filterName && filterName.length >= 3) {
        await this.getManagerPartnerList(filterName);
      }

      if (filterName && filterName.length < 3) {
        this.managerPartnerFallback = true;
      } else {
        this.managerPartnerFallback = false;
      }
    },
    async getManagerPartnerList(filterName) {
      this.managerPartnerListLoading = true;

      await this.apiService
        .getRequest(`managerpartner?nameFilter=${filterName}`)
        .then((resp) => {
          this.managerPartnerList = resp.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.managerPartnerListLoading = false;
    },
  },
};
</script>

<style scoped>
.select-manager-partner-default-container {
  display: flex;
  flex-direction: column;
}

.select-manager-partner-default-container div.tooltip-container {
  align-self: flex-end;
  margin-right: 20px;
}

.icon-tooltip-manager {
  color: var(--primary);
}
</style>
