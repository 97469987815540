<template>
  <v-container id="category-table" fluid tag="section">
    <base-material-card
      icon="mdi-domain"
      inline
      color="secondary"
      v-if="!loading"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $t("manager_companies") }}</b>
        </h1></template
      >

      <div class="button-top-table-container">
        <v-btn color="primary" rounded @click="addManagerCompany" fab>
          <v-icon large>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          color="secondary"
          @click="() => (dialogSelectedDefaultManager = true)"
          rounded
          fab
        >
          <v-icon large>mdi-cog</v-icon>
        </v-btn>
      </div>

      <!-- TABLE -->
      <v-simple-table>
        <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("edit") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(manager, i) in managerPartnerData" :key="i">
            <td>{{ manager.name }}</td>
            <td>
              <v-btn
                @click="handleEditManagerCompany(manager)"
                class="px-2 ml-1 secondary"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        color="primary"
        @input="changePage"
        v-model="page"
        :length="pageCount"
      >
      </v-pagination>
    </base-material-card>
    <div v-else class="box-loading">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>

    <!-- dialog para selecionar a empresa manager default -->
    <v-dialog
      v-model="dialogSelectedDefaultManager"
      v-if="dialogSelectedDefaultManager"
    >
      <ManagerPartnerSettings
        @close="() => (dialogSelectedDefaultManager = false)"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ManagerPartnerSettings from "./ManagerPartnerSettings";

export default {
  name: "ManagerPartnerTable",
  components: {
    ManagerPartnerSettings,
  },
  data() {
    return {
      page: 1,
      apiService: new ApiService(),
      gs: new GeneralServices(),
      pageCount: 1,
      managerPartnerData: [],
      loading: false,
      dialogSelectedDefaultManager: false,
    };
  },
  async created() {
    await this.getManagerCompaniesByPage();
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    addManagerCompany() {
      this.$router.push("managerPartner");
    },
    async changePage() {
      await this.getManagerCompaniesByPage();
    },
    async getManagerCompaniesByPage() {
      this.loading = true;
      let currentPage = this.page;
      let params = { page: currentPage };

      await this.apiService
        .postRequest(`managerpartner/list/filter`, params)
        .then((resp) => {
          const totalItemsPorPage = resp.content.perPage;

          this.managerPartnerData = resp.content.items;
          this.page = resp.content.currentPage;
          this.pageCount = Math.ceil(resp.content.total / totalItemsPorPage);
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.loading = false;
    },
    handleEditManagerCompany(manager) {
      this.$router.push(`managerPartner?id=${manager.id}`);
    },
  },
};
</script>

<style scoped>
.box-loading {
  display: grid;
  place-items: center;
}

.button-top-table-container {
  position: absolute;
  right: 0;
  top: -20px;

  display: flex;
  align-items: center;
}
</style>
